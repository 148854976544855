<template>
  <header>
    <ul class="clearfix" :class="{ limitWidth: !loginStatus }">
      <li class="fl">
        <!-- 登入後 顯示 -->
        <div class="header_btn" @click="openMenu" v-show="loginStatus" data-testid="header_btn">
          <i></i>
          <i></i>
          <i></i>
        </div>
        <!-- 通知 -->
        <HeaderNotification></HeaderNotification>
      </li>
      <li class="fr" :class="{ textCenter: !loginStatus }">
        <span class="secPortal" v-html="$t('header.secPortal')" v-show="!loginStatus"></span>
        <el-dropdown
          trigger="click"
          :hide-on-click="false"
          @command="langCommand"
          data-testid="dropdownFlag"
          v-show="showLang"
        >
          <div class="lang">
            <img :src="language.icon" alt class="lang_logo" />
            <span class="name" v-html="language.label"></span>
            <img src="@/assets/images/header/lang_down.png" alt class="lang_down" />
          </div>
          <el-dropdown-menu slot="dropdown" class="lang_dropdown_box" placement="right-end">
            <el-dropdown-item
              v-for="item in languages"
              :key="item.value"
              :command="item"
              :class="{ active: lang == item.value }"
              :data-testid="item.value"
            >
              <img :src="item.icon" alt="" />
              <span>{{ item.label }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- 登入顯示 -->
        <el-dropdown trigger="click" @command="handleCommand" data-testid="dropdown" v-show="loginStatus">
          <div class="user">
            <img src="@/assets/images/header/member_logo.png" alt class="member_logo" />
            <div class="userName">
              <span class="name">{{ userName }}</span>
            </div>
          </div>
          <el-dropdown-menu slot="dropdown" class="login_dropdown_box" placement="right-end">
            <el-dropdown-item command="goToIbPortal" class="login_back" v-show="isIb" data-testid="goToIbPortal">
              <div class="icon"></div>
              <span>{{ $t('common.button.goToIB') }}</span>
            </el-dropdown-item>
            <el-dropdown-item command="logout" class="logout" data-testid="logout">
              <div class="icon"></div>
              <span>{{ $t('common.button.logout') }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </li>
    </ul>
  </header>
</template>

<script>
import helper from '@/util/signinHelper';
import HeaderNotification from '@/components/notification/HeaderNotification';
import { sensorsRegister } from '@/util/sensorsdata';

export default {
  components: {
    HeaderNotification
  },
  name: 'vHeader',
  props: { matches: Boolean },
  data() {
    return {
      isIb: false,
      showLang: true,
      language: '',
      languages: [
        {
          value: 'en_US',
          label: 'English',
          icon: require('@/assets/images/flag/uk_flag.png')
        },
        {
          value: 'zh_CN',
          label: '中文',
          icon: require('@/assets/images/flag/cn_flag.png')
        },
        {
          value: 'fr_FR',
          label: 'Français',
          icon: require('@/assets/images/flag/fr_flag.png')
        },
        {
          value: 'ko',
          label: '한국어',
          icon: require('@/assets/images/flag/ko_flag.png')
        },
        {
          value: 'th',
          label: 'ภาษาไทย',
          icon: require('@/assets/images/flag/th_flag.png')
        },
        {
          value: 'vi',
          label: 'Tiếng Việt',
          icon: require('@/assets/images/flag/vi_flag.png')
        },
        {
          value: 'es',
          label: 'Español',
          icon: require('@/assets/images/flag/es_flag.png')
        },
        {
          value: 'pt',
          label: 'Português',
          icon: require('@/assets/images/flag/pt_flag.png')
        },
        {
          value: 'de',
          label: 'Deutsch',
          icon: require('@/assets/images/flag/de_flag.png')
        },
        {
          value: 'id',
          label: 'Indonesian',
          icon: require('@/assets/images/flag/id_flag.png')
        },
        {
          value: 'ar',
          label: 'عربي',
          icon: require('@/assets/images/flag/ar_flag.png')
        },
        {
          value: 'ru',
          label: 'Русский',
          icon: require('@/assets/images/flag/ru_flag.png')
        },
        {
          value: 'it',
          label: 'Italian',
          icon: require('@/assets/images/flag/it_flag.png')
        },
        {
          value: 'ja',
          label: '日本語',
          icon: require('@/assets/images/flag/ja_flag.png')
        },
        {
          value: 'fa',
          label: 'Farsi',
          icon: require('@/assets/images/flag/fa_flag.png')
        }
      ]
    };
  },
  watch: {
    loginStatus(loginStatus) {
      if (loginStatus) {
        this.getUserInfo();
      }
    },
    language(lang) {
      this.lang = lang.value;
      document.body.dir = lang.value === 'ar' ? 'rtl' : 'ltr';
    },
    lang: {
      immediate: true,
      handler(val) {
        // 如果找不到預設第一筆
        this.language = this.languages.find(f => f.value == val) || this.languages[0];
        sensorsRegister({current_lang: val});
        this.setChatLanguage(val);
      }
    },
    $route: {
      handler: function (route) {
        this.showLang = route.meta.restrictLang ? false : true;
      },
      immediate: true
    }
  },
  computed: {
    userName() {
      return this.$store.state.common.userName;
    },
    loginStatus() {
      return this.$store.state.common.loginStatus;
    },
    userType() {
      return this.$store.state.common.userType;
    },
    lang: {
      get() {
        return this.$store.state.common.lang;
      },
      set(value) {
        return this.$store.commit('common/setLang', value);
      }
    }
  },
  methods: {
    openMenu() {
      this.$emit('openMenu');
    },
    logout() {
      helper.signOut(null,'manually');
    },
    handleCommand(command) {
      if (command == 'logout') this.logout();
      else if (command == 'goToIbPortal' && this.isIb) this.$redirect.redirectToIbPortal();
    },
    langCommand(command) {
      this.language = command;
      //see if language is changed in the landing page
      if (!this.loginStatus) {
        this.$store.commit('common/setIsChangedLangAtLandingPage', true);
      }
    },
    getUserInfo() {
      this.isIb = this.userType === 5 || this.userType === 16 ? true : false;
    },
    setChatLanguage(val) {
      zE('messenger:set', 'locale', val.replace('_', '-')); // change to BCP standard
    }
  },
  mounted() {
    //for already logged-in users
    if (this.loginStatus) this.getUserInfo();

    let callback = (val, oldVal, uri) => {
      console.log('localStorage change', val);
      if (val != this.userName) location.reload();
    };

    this.$ls.on('setUserName', callback);
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/layout/header.scss';
</style>
